import React, { forwardRef } from "react";
import styles from "./Projects.module.scss";

const ProjectCard = (props) => {

	return (
		<>
			{
				props.link ?
					<a
						className={styles.projectItem}
						href={props.link}
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => props.handleClick({
							title: props.title,
							description: props.description,
							link: props.link,
							tag: "a",
						})}
					>
						<div className={styles.text}>
							<h3>{props.title}</h3>
							<p>{props.description}</p>
						</div>
						<div className={styles.viewCode}>{props.viewText}</div>
					</a>
				:
					<div
						className={styles.projectItem}
						onClick={() => props.handleClick({
							title: props.title,
							description: props.description,
							link: "",
							tag: "div",
						})}
					>
						<div className={styles.text}>
							<h3>{props.title}</h3>
							<p>{props.description}</p>
						</div>
					</div>
			}
		</>
	)
};

const Projects = forwardRef((props, ref) => {

	const handleClick = (additionalInfo) => {
		props.gtmLink(`Project (${additionalInfo.title})`, additionalInfo);
		props.addInteraction(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'click', 'Click', "Project", additionalInfo);
	};

	return (
		<section ref={ref} id="projects" className={styles.projects}>
			<div className={styles.container}>
				<h2>Projects</h2>
				<div className={styles.projectList}>
					{
						props.projects ? props.projects.map((project, i) => (
							<ProjectCard
								key={`project-${i}`}
								title={project.title}
								description={project.description}
								viewText={project.viewText}
								link={project.link}
								handleClick={handleClick}
							/>
						)) : <></>
					}
				</div>
			</div>
		</section>
	)
})

export default Projects;