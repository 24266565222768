import React, { forwardRef } from "react";
import styles from "./Navbar.module.scss";
import PersonalLogo from "../../assets/personal_logo_small.png";


const Navbar = forwardRef((props, ref) => {

	const handleClick = (additionalInfo) => {
		props.gtmPage(`Navbar (${additionalInfo.path})`, additionalInfo);
		props.addInteraction(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'section', 'Navigation', "Navbar", additionalInfo);
	};

	return (
		<nav ref={ref} className={styles.navbar}>
			<div className={styles.container}>
				<h1 className={styles.logo}>
					<img
						className={styles.personalLogo}
						src={PersonalLogo}
						alt="Patrick Lyden Logo"
					/>
				</h1>
				<ul className={styles.navLinks}>
					<li><a href="#about" onClick={() => handleClick({path: "#about"})}>About</a></li>
					<li><a href="#skills" onClick={() => handleClick({path: "#skills"})}>Skills</a></li>
					<li><a href="#projects" onClick={() => handleClick({path: "#projects"})}>Projects</a></li>
					<li><a href="#contact" onClick={() => handleClick({path: "#contact"})}>Contact</a></li>
				</ul>
			</div>
		</nav>
	)
})

export default Navbar;