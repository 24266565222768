import React, { forwardRef } from "react";
import styles from "./About.module.scss";


const About = forwardRef((props, ref) => {

	return (
		<section ref={ref} id="about" className={styles.about}>
			<div className={styles.container}>
				<h2>About Me</h2>
				<p>
					I am a dedicated Full Stack Web Developer with a strong foundation in Electrical Engineering and a passion for solving complex problems through technology. With experience in both frontend and backend development, I specialize in creating scalable, user-focused solutions using modern tools like ReactJS, Django, and Python.
				</p>
				<br />
				<p>
					I’ve contributed to projects ranging from building generative AI-powered applications to optimizing web performance for diverse clients. My technical expertise is complemented by a collaborative mindset, making me an approachable and reliable team player who values mentoring others and driving innovation. Whether leading large-scale web migrations or developing machine learning models, I bring precision, creativity, and a commitment to excellence to every project.
				</p>
			</div>
		</section>
	)
})

export default About;