import React, { forwardRef } from "react";
import styles from "./Footer.module.scss";

const currentYear = new Date().getFullYear();

const Footer = forwardRef((props, ref) => {

	return (
		<footer ref={ref} className={styles.footer}>
			<div className={styles.container}>
				<p>© {currentYear} Patrick Lyden. All rights reserved.</p>
			</div>
		</footer>
	)
})

export default Footer;