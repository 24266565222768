import React, { forwardRef } from "react";
import styles from "./Contact.module.scss";
import { FaEnvelope, FaPhone, FaLinkedin, FaGithub } from "react-icons/fa";


const Contact = forwardRef((props, ref) => {

	const handleClick = (additionalInfo) => {
		props.gtmLink(`Contact (${additionalInfo.type})`, additionalInfo);
		props.addInteraction(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'click', 'Click', "Contact", additionalInfo);
	};

	return (
		<section ref={ref} id="contact" className={styles.contact}>
			<div className={styles.container}>
				<h2>Contact</h2>
				<ul className={styles.contactList}>
          <li>
            <FaEnvelope className={styles.icon} />
            <a
              href="mailto:syncc12@verizon.net"
              onClick={() => handleClick({type: "Email"})}
            >
              syncc12@verizon.net
            </a>
          </li>
          {/* <li>
            <FaPhone className={styles.icon} />
            <a
              href="tel:+1234567890"
              onClick={() => handleClick({type: "Phone"})}
            >
              +1 (234) 567-890
            </a>
          </li> */}
          <li>
            <FaLinkedin className={styles.icon} />
            <a
              href="https://www.linkedin.com/in/patrick-lyden-73103097/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleClick({type: "LinkedIn"})}
            >
              linkedin.com/in/patrick-lyden-73103097
            </a>
          </li>
          <li>
            <FaGithub className={styles.icon} />
            <a
              href="https://github.com/syncc12"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleClick({type: "GitHub"})}
            >
              github.com/syncc12
            </a>
          </li>
        </ul>
			</div>
		</section>
	)
})

export default Contact;