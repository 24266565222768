import React, { useEffect } from "react";
import styles from "./Home.module.scss";
import {
	Navbar,
	Hero,
	About,
	Skills,
	Projects,
	Contact,
	Footer,
} from "../../components";
import { PROJECTS, SKILLS } from "../../constants";
import useGTM from "../../hooks/useGTM";
import { addInteraction } from "../../util/tracking";

function Home(props) {
	const gtm = useGTM('scroll', 'link', 'click', 'mouseOver', 'inView');
	const navbarViewRef = gtm.inView(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'navbar');
	const heroViewRef = gtm.inView(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'hero', 0.8);
	const aboutViewRef = gtm.inView(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'about', 0.5);
	const projectsViewRef = gtm.inView(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'projects', 0.25);
	const skillsViewRef = gtm.inView(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'skills', 0.55);
	const contactViewRef = gtm.inView(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'contact', 0.5);
	const footerViewRef = gtm.inView(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'footer');

  useEffect(() => {
		const handleScroll = () => {
			const scrollPercentage = (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100;
			if (scrollPercentage % 10 === 0) {
				addInteraction(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'scroll', 'User Interaction', `${scrollPercentage}% Scroll`);
				console.log('handleScroll - props.visitId', props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')));
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
  }, []);

	return (
		<div className={styles.home}>
			<Navbar
				ref={navbarViewRef}
				gtmPage={gtm.click}
				visitId={props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID'))}
				addInteraction={addInteraction}
			/>
			<Hero
				ref={heroViewRef}
				gtmPage={gtm.click}
				visitId={props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID'))}
				addInteraction={addInteraction}
			/>
			<About
				ref={aboutViewRef}
			/>
			<Projects
				ref={projectsViewRef}
				projects={PROJECTS}
				gtmLink={gtm.link}
				visitId={props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID'))}
				addInteraction={addInteraction}
			/>
			<Skills
				ref={skillsViewRef}
				skills={SKILLS}
				gtmMouseOver={gtm.mouseOver}
				visitId={props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID'))}
				addInteraction={addInteraction}
			/>
			<Contact
				ref={contactViewRef}
				gtmLink={gtm.link}
				visitId={props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID'))}
				addInteraction={addInteraction}
			/>
			<Footer
				ref={footerViewRef}
			/>
		</div>
	)
}

export default Home;