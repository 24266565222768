import React, { forwardRef } from "react";
import styles from "./Skills.module.scss";


const Skills = forwardRef((props, ref) => {

	const handleMouseOver = (additionalInfo) => {
		props.gtmMouseOver(`Skills (${additionalInfo.skill} ${additionalInfo.frameworks.length > 0 && `- ${additionalInfo.frameworks.join(", ")}`})`, additionalInfo);
		props.addInteraction(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'mouseOver', 'Mouse Over', "Skills", additionalInfo);
	};

	return (
		<section ref={ref} id="skills" className={styles.skills}>
			<div className={styles.container}>
				<h2>Skills</h2>
				<div className={styles.skillList}>
					{
						props.skills ? props.skills.map((e, i) => (
							<div
								key={`skill-${i}`}
								className={styles.skillItem}
								onMouseEnter={() => handleMouseOver({
									skill: e.skill,
									frameworks: `[${e.frameworks.length > 0 && `- ${e.frameworks.join(", ")}`}]`,
								})}
							>
								{e.skill} {e.frameworks.length > 0 && `(${e.frameworks.join(", ")})`}
							</div>
						)) : <></>
					}
				</div>
			</div>
		</section>
	)
})

export default Skills;