import axios from 'axios';
import {
  API_URL
} from '../constants';

export const getIPAddress = async () => {
  const res = await axios.get(`${API_URL}/blackbox`);
  const visitId = res.data.visitId;
  sessionStorage.setItem('PORTFOLIO_VISIT_ID', visitId);
  return visitId;
}

export const addInteraction = async (visitId, event, eventCategory, eventLabel, additionalInfo={}) => {
  const payload = {
    visit_id: visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')),
    event: event,
    event_category: eventCategory,
    event_label: eventLabel,
    additional_info: additionalInfo,
  };
  const res = await axios.post(`${API_URL}/blackbox`, payload);
  return res.status;
}