

export const API_URL = process.env.REACT_APP_API_URL;

export const PROJECTS = [
	{
		title: "Apply SamurAI",
		description: "This site uses AI to help job seakers apply to jobs.",
		link: "https://applysamurai.com",
		viewText: "View Site",
	},
	{
		title: "Hockey Prediction AI",
		description: "This is a machine learning project to predict the outcomes of future NHL games.",
		link: "https://www.linkedin.com/in/patrick-lyden-73103097/details/projects/?profileUrn=urn%3Ali%3Afsd_profile%3AACoAABR4pxUBk1kRm_MjshYznh6g10bclZABTFs",
		viewText: "View More",
	},
	{
		title: "beatBread Company Website",
		description: "I converted this site from a Wordpress site to a React site, and maintained and updated the site while at beatBread.",
		link: "https://beatbread.com",
		viewText: "View Site",
	},
	{
		title: "chordCash Company Website",
		description: "I converted this site from a Wordpress site to a React site, and maintained and updated the site while at beatBread.",
		link: "https://chordcash.com",
		viewText: "View Site",
	},
	{
		title: "chordCash Whitelabel Site",
		description: "This is one of the many whitelabel sites I built while at beatBread.",
		link: "https://indieamplify.chordcash.com",
		viewText: "View Site",
	},
];

export const SKILLS = [
	{skill: "JavaScript", frameworks: ["React", "Node.js", "Next.js", "React Native"]},
	{skill: "Python", frameworks: ["Django", "Flask"]},
	{skill: "TypeScript", frameworks: ["React", "Next.js"]},
	{skill: "Database Management", frameworks: ["MongoDB", "PostgreSQL"]},
	{skill: "Version Control", frameworks: ["Git", "GitHub"]},
	{skill: "Cloud Services", frameworks: ["AWS", "Render"]},
];