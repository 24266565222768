import { useEffect, useRef } from 'react';
import { addInteraction } from '../util/tracking';

const useScrollTracking = (enable=false) => {
  useEffect(() => {
    if (enable) {
      const handleScroll = () => {
        const scrollPercentage = (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100;
        window.gtag('event', "scroll", {
          event_category: 'User Interaction',
          event_label: `${scrollPercentage}% Scroll`,
        });
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [enable]);
};

const useLinkClick = (label, additionalInfo={}) => {
  window.gtag('event', `link_click - Navigation - ${label}`, {
    event_category: 'Navigation',
    event_label: label,
    ...additionalInfo,
  });
};

const useClick = (label, additionalInfo={}) => {
  window.gtag('event', `click - ${label}`, {
    event_category: 'Click',
    event_label: label,
    ...additionalInfo,
  });
};

const usePageTracking = (location, additionalInfo={}) => {
  window.gtag('config', 'G-XDZ9VCQ1YW', {
    page_path: location,
    ...additionalInfo,
  });
};

const useInView = (viewId, label, threshold=0.1, additionalInfo={}) => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        addInteraction(viewId, 'inView', 'In View', label, {
          threshold: threshold,
          isInView: entry.isIntersecting,
          ...additionalInfo,
        });
        window.gtag('event', `inView`, {
          event_category: 'In View',
          event_label: `${label}:${entry.isIntersecting}`,
          threshold: threshold,
          isInView: entry.isIntersecting,
          ...additionalInfo,
        });
      },
      {
        root: null, // Default is viewport
        rootMargin: '0px', // Adjust if needed
        threshold: threshold, // Trigger when 10% of the element is visible
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return ref;
};

const useMouseOver = (label, additionalInfo={}) => {
  addInteraction('mouseOver', 'Mouse Over', label, additionalInfo);
  window.gtag('event', `mouseOver - ${label}`, {
    event_category: 'Mouse Over',
    event_label: label,
    ...additionalInfo,
  });
};

const useGTM = (...args) => {
  useScrollTracking(args.includes('scroll'));
  const linkClick = useLinkClick;
  const pageTrack = usePageTracking;
  const click = useClick;
  const inView = useInView;
  const mouseOver = useMouseOver;
  return ({
    ...args.includes('link') && {link: linkClick},
    ...args.includes('page') && {page: pageTrack},
    ...args.includes('click') && {click: click},
    ...args.includes('inView') && {inView: inView},
    ...args.includes('mouseOver') && {mouseOver: mouseOver},
  });
};

export default useGTM;
