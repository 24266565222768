import React, { useState, useEffect } from 'react';
import './App.scss';
import {
  Home,
} from "./pages";
import { getIPAddress } from './util/tracking';

function App() {
  const [visitId, setVisitId] = useState(parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')) || null);

  useEffect(() => {
    const fetchVisitId = async () => {
      const newVisitId = await getIPAddress();
      setVisitId(newVisitId);
      console.log('newVisitId', newVisitId);
    }
    if (!visitId && visitId !== 0) {
      fetchVisitId();
    }
  }, [visitId]);

  return (
    <div className="App">
      <Home visitId={visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID'))} />
    </div>
  );
}

export default App;
