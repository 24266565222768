import React, { forwardRef } from "react";
import styles from "./Hero.module.scss";


const Hero = forwardRef((props, ref) => {

	const handleClick = (additionalInfo) => {
		props.gtmPage(`Hero Link (${additionalInfo.path})`, additionalInfo);
		props.addInteraction(props.visitId || parseInt(sessionStorage.getItem('PORTFOLIO_VISIT_ID')), 'section', 'Navigation', "Hero Link", additionalInfo);
	};

	return (
		<section ref={ref} className={styles.hero}>
			<div className={styles.container}>
        <h1>Hi, I'm Patrick Lyden</h1>
        <p>Full Stack Web Developer</p>
        <a
					href="#projects"
					className={styles.btn}
					onClick={() => handleClick({
						path: "#projects",
						notes: "View My Work"
					})}
				>
					View My Work
				</a>
      </div>
		</section>
	)
})

export default Hero;